import { UilAngleRight, UilArrowRight } from "@iconscout/react-unicons";
import { Button as MButton, ButtonProps as MButtonProps } from "@mantine/core";
import Link, { LinkProps } from "next/link";
import { ButtonHTMLAttributes, FC, forwardRef } from "react";

import { classNames } from "../utils/classNames";

type ExtendedButtonProps = {
  className?: string;
  small?: boolean;
  variant?: ButtonVariant;
};
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ExtendedButtonProps;

// Chevron icon that animates to arrow-right on hover
// When including in a button, you'll need to make it flex and items-center
// Also need to add a "group" className
export const AnimatedIcon: FC<{ small?: boolean }> = ({ small }) => {
  return (
    <div className={classNames("relative w-3.5", small ? "h-3" : "h-6")}>
      <div
        className={classNames(
          "absolute opacity-100 translate-x-0 group-hover:opacity-0 group-hover:translate-x-1 transition-all",
          small ? "-top-1.5" : ""
        )}
      >
        <UilAngleRight />
      </div>
      <div
        className={classNames(
          "absolute left-0 opacity-0 group-hover:opacity-100 transition-all group-hover:translate-x-1",
          small ? "-top-1.5" : ""
        )}
      >
        <UilArrowRight />
      </div>
    </div>
  );
};

type ButtonVariant =
  | "black"
  | "neon"
  | "blueoutline"
  | "outline"
  | "blue"
  | "unstyled";
export const buttonClassNames = ({
  small,
  variant,
  className,
}: {
  small?: boolean;
  variant?: ButtonVariant;
  className?: string;
}) => {
  return classNames(
    "h-auto font-medium rounded-full transition-all outline outline-0 focus-visible:outline-[10px]",
    small
      ? "px-6 py-3 text-[16px] active:outline-[4px] hover:outline-[8px]"
      : "px-8 py-4 text-[18px] active:outline-[5px] hover:outline-[10px]",
    variant === "unstyled"
      ? "text-frecBlack outline-none ring-0"
      : variant === "outline"
      ? // outline uses ring inset to avoid changing the size of the button
        "text-frecBlack ring-inset ring-2 ring-frecBlack outline-frecNeon"
      : variant === "neon"
      ? "bg-frecNeon outline-frecNeon/25 text-frecBlack"
      : variant === "blueoutline"
      ? "ring-2 ring-frecBlue outline-frecBlue text-frecBlue bg-frecBeige hover:bg-frecBeige hover:text-frecBlue"
      : variant === "blue"
      ? "bg-frecBlue outline-frecBlue/25 text-white"
      : "bg-frecBlack outline-frecNeon text-frecBeige",
    className
  );
};

export const smallOutlineClassNames =
  "!outline-frecBeige ring-frecBeige ring-1 !text-[12px] !py-2.5 !px-4 leading-5";

export const LinkButton = forwardRef<
  HTMLAnchorElement,
  LinkProps & ExtendedButtonProps
>(function LinkButton({ className, variant, small, ...props }, ref) {
  return (
    <Link
      {...props}
      className={buttonClassNames({ small, variant, className })}
      ref={ref}
    />
  );
});

export const Button = forwardRef<HTMLButtonElement, MButtonProps & ButtonProps>(
  function Button({ className, classNames, variant, small, ...props }, ref) {
    return (
      <MButton
        {...props}
        className={buttonClassNames({ small, variant, className })}
        classNames={classNames ?? { label: "overflow-visible" }}
        ref={ref}
      />
    );
  }
);

export const TextButton = forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, "variant">
>(function TextButton({ className, small, ...props }, ref) {
  return (
    <button
      {...props}
      className={buttonClassNames({ small, variant: "unstyled", className })}
      ref={ref}
    />
  );
});

export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: React.ReactNode;
}
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, onClick, disabled, icon, type, ...props }, ref) => {
    return (
      <button
        ref={ref}
        type={type ?? "button"}
        className={classNames(
          "text-frecXDarkGray hover:text-frecBlack",
          disabled ? "opacity-50 cursor-default" : "",
          className ?? ""
        )}
        onClick={onClick}
        disabled={disabled}
        {...props}
      >
        {icon}
      </button>
    );
  }
);
